import * as Yup from 'yup';
import { UserRoles } from '../types/Users';

export const UserSchema = Yup.object({
    firstName: Yup.string().trim().required('First Name is required.'),
    lastName: Yup.string().trim().required('Last Name is required.'),
    emailAddress: Yup.string().trim().required('Email is required.'),
    contactNumber: Yup.number().required('Contact Number is required.'),
    address: Yup.string().trim().required('Address is required.'),
    role: Yup.string().trim().required('Role is required.'),
    clientId: Yup.string().when('role', {
        is: UserRoles.Client,
        then: Yup.string().required('Client Id is required.'),
    }),
});

export const UpdateProfileSchema = Yup.object({
    firstName: Yup.string().trim().required('First Name is required.'),
    lastName: Yup.string().trim().required('Last Name is required.'),
    contactNumber: Yup.number().required('Contact Number is required.'),
    address: Yup.string().trim().required('Address is required.'),
});

export const ChangePasswordSchema = Yup.object({
    oldPassword: Yup.string().trim().required('Old Password is required.'),
    newPassword: Yup.string().trim().required('New Password is required.'),
    confirmPassword: Yup.string()
        .trim()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required.'),
});

export const LoginSchema = Yup.object({
    emailAddress: Yup.string()
        .email('Invalid email address')
        .required('Email is required.'),
    password: Yup.string().required('Password is required.'),
});

export const AdminPasswordSchema = Yup.object({
    adminPassword: Yup.string().required('Password is required.'),
});
