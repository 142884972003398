import { Client } from '../../types/Client';

export enum UserRoles {
    Client = 'Client',
    Admin = 'Admin',
    Rider = 'Rider',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}
export interface User {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    contactNumber: string;
    address: string;
    role: UserRoles;
    status: UserStatus;
    client: Client;
}

export interface UserSessionParams {
    userData: User;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
    authToken: string | undefined;
    onLogin: (emailAddress: string, password: string) => Promise<any>;
    onLogout: () => void;
    isLoggedIn: boolean;
    isNewUser: boolean;
    setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>;
    sessionLoading: boolean;
}
