import { Box, CircularProgress } from '@material-ui/core';

export default function LoadingBox() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
        >
            <CircularProgress />
        </Box>
    );
}
