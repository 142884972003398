import React, { useState } from 'react';
import { useSession } from '../providers/SessionProvider';
import { useFormik } from 'formik';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Message, { MessageTypes } from '../common/Message';
import ErrorMessages from '../common/mapErrorMessage';
import Image from 'next/image';
import TextInputPassword from '../common/TextInputPassword';
import Copyright from '../common/Copyright';
import LoadingBox from '../common/LoadingBox';

import { LoginSchema } from '../schema/UserSchema';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginTop: 30,
        marginBottom: 30,
    },
}));

const Login = () => {
    const classes = useStyles();
    const { onLogin } = useSession();
    const [isMessage, showMessage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState<MessageTypes>(MessageTypes.info);

    const formik = useFormik({
        initialValues: {
            emailAddress: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async ({ emailAddress, password }) => {
            setLoading(true);
            const res = await onLogin(emailAddress, password);
            if (res?.error) {
                console.log(res);
                showMessage(true);
                setMessage(ErrorMessages(res.message));
                setType(MessageTypes.error);
            }
            setLoading(false);
        },
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Message
                open={isMessage}
                type={type}
                showMessage={showMessage}
                message={message}
            />

            <div className={classes.paper}>
                <Image
                    src="/jnu-logo.png"
                    alt="Picture of the author"
                    width={200}
                    height="100%"
                />
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    className={classes.header}
                >
                    JNU Order Tracking
                </Typography>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>

                {!isLoading ? (
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={formik.handleSubmit}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="emailAddress"
                            autoComplete="email"
                            autoFocus
                            onChange={formik.handleChange}
                            value={formik.values.emailAddress}
                            helperText={
                                formik.touched.emailAddress &&
                                formik.errors.emailAddress
                            }
                            error={
                                !!formik.errors.emailAddress &&
                                formik.touched.emailAddress
                            }
                        />
                        <TextInputPassword
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            helperText={
                                formik.touched.password &&
                                formik.errors.password
                            }
                            error={
                                !!formik.errors.password &&
                                formik.touched.password
                            }
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        /> */}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            type="submit"
                        >
                            Sign In
                        </Button>
                    </form>
                ) : (
                    <LoadingBox />
                )}
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default Login;
